import { ref, watch, reactive } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'

export default function useProductActivity(props) {
  const customerHeaderFilter = () => reactive({
    action: ref(''),
  })
  const tableHeaderFilter = customerHeaderFilter()

  const y = new Date().getFullYear()
  const monthFilter = ref(null)
  const yearFilter = ref(y)

  const tableColumns = ref([
    {
      label: 'Ngày tháng',
      key: 'ngay-thang',
    },
    {
      label: 'Người xem',
      key: 'nguoi-xem',
      tdClass: 'minw-190',
    },
    {
      label: 'Thiết bị',
      key: 'thiet-bi',
    },
  ])
  const filterDateRange = ref([])
  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const activityList = ref([])
  const totalActivity = ref(0)

  const buildQueryWithCustom = customFilter => http.buildQuery({
    ...({
      page: currentPage.value - 1,
      perpage: perPage.value,
      from: http.getISODate(filterDateRange.value[0]?.setHours(0, 0, 0, 0)),
      to: http.getISODate(filterDateRange.value[1]?.setHours(23, 59, 59, 999)),
      product_id: props.productId,
    }),
    ...customFilter,
  })

  const fetchActivity = customFilter => {
    const queryString = buildQueryWithCustom({
      ...customFilter,
      ...tableHeaderFilter,
    })
    return http.handle(store.dispatch('product/fetchActivityProduct', {
      productId: props.productId,
      q: queryString,
    }), res => {
      activityList.value = res.data || []
      totalActivity.value = res.total
    })
  }
  watch([currentPage, perPage, filterDateRange], () => {
    fetchActivity()
  })
  watch(tableHeaderFilter, () => {
    fetchActivity()
  })

  const clearHeaderFilter = initFilter => {
    Object.assign(tableHeaderFilter, customerHeaderFilter(), initFilter)
    fetchActivity()
  }

  return {
    monthFilter,
    yearFilter,
    tableColumns,
    perPage,
    currentPage,
    fetchActivity,
    activityList,
    totalActivity,
    perPageOptions,
    filterDateRange,
    tableHeaderFilter,
    clearHeaderFilter,
  }
}
